const faqs = [
  {
    question: "1. Requisitos para viajar a Estados Unidos:",
    answer: (
      <div>
        <p>- Pasaporte válido: El primer requisito es tener un pasaporte argentino válido. Asegúrate de que tu pasaporte tenga una vigencia de al menos seis meses a partir de la fecha prevista de ingreso a Estados Unidos. <br/> <br/>
        - Visa de no inmigrante: La mayoría de los ciudadanos argentinos deben obtener una visa de no inmigrante antes de viajar a Estados Unidos. El tipo de visa que necesitarás dependerá del propósito de tu viaje. Algunas de las visas de no inmigrante más comunes son la visa de turista (B-2), la visa de negocios (B-1) y la visa de estudiante (F-1). Para solicitar una visa, deberás completar el formulario DS-160, pagar la tarifa correspondiente y programar una entrevista en la embajada o consulado de Estados Unidos en Argentina. <br/> <br/>
       - Autorización de viaje: Si viajas por un período de hasta 90 días por motivos de turismo o negocios puedes ser elegible para el programa Visa Waiver Program (VWP). Este programa permite ingresar a Estados Unidos sin una visa siempre que se obtenga una autorización de viaje conocida como ESTA (Electronic System for Travel Authorization). La solicitud de ESTA debe realizarse en línea antes de viajar y tiene un costo asociado. <br/> <br/>
       - Comprobantes de solvencia económica: Es posible que se te solicite proporcionar pruebas de que cuentas con los recursos económicos suficientes para cubrir tus gastos durante tu estadía en Estados Unidos. Estos documentos pueden incluir estados de cuenta bancarios, tarjetas de crédito, cartas de empleo u otros comprobantes que demuestren tu capacidad de costear el viaje. Asegúrate de tener estos documentos a mano para evitar problemas durante tu ingreso a Estados Unidos.á <br/> <br/>
       - Seguro médico: Aunque no es un requisito obligatorio, se recomienda encarecidamente que cuentes con un seguro médico válido que cubra cualquier eventualidad durante tu estancia en Estados Unidos. Los costos médicos en este país pueden ser muy elevados, y un seguro te brindará tranquilidad y protección financiera en caso de enfermedad o accidente. <br/> <br/>
       - Boleto de regreso: Es posible que se te solicite mostrar un boleto de regreso a Argentina. Esto sirve como prueba de que tienes intenciones de abandonar Estados Unidos al final de tu visita. <br/> <br/>- Cumplimiento de normas de aduana y seguridad: Recuerda que existen regulaciones estrictas sobre los artículos que puedes llevar contigo al ingresar a Estados Unidos. Asegúrate de conocer las restricciones aduaneras y de seguridad, y evita llevar artículos prohibidos o restringidos.

</p>
      </div>
    ),
  },
  {
    question: "2. ¿Qué vacunas son necesarias para viajar a Estados Unidos?",
    answer: (
      <div>
      <p>Desde el 12 de mayo del 2023, los visitantes argentinos ya no necesitarán una prueba de vacunación completa para poder ingresar a los Estados Unidos. Esto se debe a que el gobierno de Estados Unidos ha decidido eliminar este requisito junto con la finalización de la emergencia de salud pública por COVID-19 el 11 de mayo. 
</p>
    </div>
    ),
  },


];

export default faqs;
